<template>
  <div class="lawyers-assigned p-2">
    <p class="font-bold mb-2">Lawyers Assigned</p>

    <!-- Loading State -->
    <div v-if="isLoading" class="list px-2 py-1">
      <lawyer
        isLoading
        :lawyer="{ name: '', bank: '' }"
        v-for="n in 4"
        :key="n"
      ></lawyer>
    </div>
    <!-- List  -->
    <div v-else class="list py-1">
      <div v-if="lawyers.length < 1" class="lawyer-empty">
        <NoData message="No lawyers available"></NoData>
      </div>
      <DynamicScroller v-else :items="lawyers" :min-item-size="120">
        <template v-slot="{ item, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="item.id"
            :size-dependencies="[item.name, item.bank]"
            class="px-2"
          >
            <lawyer
              :isRemove="lawyers.length > 1"
              :lawyer="item"
              @remove="
                (lawyer) => {
                  $emit('remove', lawyer);
                }
              "
            >
            </lawyer>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
      <InfiniteLoading v-if="isEdit" @infinite="infiniteScrollHandler">
        <span slot="no-more"></span>
      </InfiniteLoading>
    </div>
  </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import InfiniteLoading from "vue-infinite-loading";

import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    InfiniteLoading,
    Lawyer: () => import("./Lawyer"),
    NoData: () => import("@/components/GlobalComponents/NoData/NoData")
  },
  mixins: [],
  props: {
    lawyers: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    infiniteScrollHandler(state) {
      this.$emit("infinite-scroll", state);
    }
  }
};
</script>

<style lang="scss">
.lawyers-assigned {
  .list {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
